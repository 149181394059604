var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": _vm.withoutInvalidText ? '0' : ['16px', '24px'],
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [!!_vm.label ? _c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('c-box', {
    attrs: {
      "font-size": "44px"
    }
  }, [_c('Multiselect', {
    staticClass: "d-multi-select",
    attrs: {
      "options": _vm.options,
      "multiple": true,
      "close-on-select": false,
      "show-labels": false,
      "option-height": 204,
      "loading": _vm.isLoading,
      "disabled": _vm.isDisabled,
      "label": "programsService",
      "track-by": "id"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  })], 1), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid && !_vm.withoutInvalidText ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }