<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="withoutInvalidText ? '0' :['16px', '24px']"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-if="!!label"
      :font-size="['14px', '16px']"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
    </c-form-label>
    <c-box
      font-size="44px"
    >
      <Multiselect
        v-model="modelValue"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :show-labels="false"
        :option-height="204"
        :loading="isLoading"
        :disabled="isDisabled"
        label="programsService"
        track-by="id"
        class="d-multi-select"
      />
    </c-box>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid && !withoutInvalidText"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'BaseInputMultiTag',
  components: {
    Multiselect,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    withoutInvalidText: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'trigger-change', 'blur'],
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
    isIncludesValue(value) {
      return this.options.some((it) => it.value === value)
    },
  },
}
</script>

<style scoped>
::v-deep .d-multi-select.multiselect, ::v-deep .d-multi-select .multiselect__tags {
  min-height: 60px;
}

::v-deep .d-multi-select .multiselect__tags {
  border-radius: 8px;
}

::v-deep .d-multi-select .multiselect__tag {
  font-size: 18px;
}

::v-deep .d-multi-select .input {
  font-size: 18px;
}

::v-deep .d-multi-select .multiselect__content-wrapper {
  font-size: 18px;
}

::v-deep .d-multi-select .multiselect__select,
::v-deep .d-multi-select .multiselect__spinner {
  top: 11px;
  background-color: white;
}

::v-deep .d-multi-select .multiselect__placeholder {
  padding-top: 7px;
  font-size: 18px;
}

</style>
